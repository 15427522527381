var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"um-blog"},[_c('div',{staticClass:"um-blog__container"},[_c('h2',{staticClass:"um-blog__header"},[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),_c('div',{staticClass:"um-blog__posts"},_vm._l((_vm.posts),function(post){return _c('um-post',{key:JSON.stringify(post),attrs:{"image":post?.image?.replace(
            'https://bgcsstaging.wpengine.com',
            'https://unmask.com/blog'
          ),"title":post.title,"link":post.link,"category-title":post.categoryTitle,"category-link":post?.categoryLink?.replace(
            'https://bgcsstaging.wpengine.com',
            'https://unmask.com/blog'
          )}})}),1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="um-blog">
    <div class="um-blog__container">
      <h2 class="um-blog__header">{{ title }}</h2>
      <div class="um-blog__posts">
        <um-post
          v-for="post in posts"
          :image="
            post?.image?.replace(
              'https://bgcsstaging.wpengine.com',
              'https://unmask.com/blog'
            )
          "
          :title="post.title"
          :link="post.link"
          :category-title="post.categoryTitle"
          :category-link="
            post?.categoryLink?.replace(
              'https://bgcsstaging.wpengine.com',
              'https://unmask.com/blog'
            )
          "
          :key="JSON.stringify(post)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UmPost from '@/components/um-post';

export default {
  name: 'um-blog',
  components: { UmPost },
  data() {
    return {
      posts: []
    };
  },
  fetchOnServer: true,
  /**
   * Fetch the goodies
   * @returns {Promise<void>}
   */
  async fetch() {
    const posts = [];
    const categories = [];
    const media = [];
    const apiRoot = `/blog/wp-json/wp/v2/`;
    const apiUrl = `https://unmask.com${apiRoot}`;
    const TIMEOUTDURATION = 2000;

    try {
      this.$http.setHeader('Host', 'unmask.com');

      const timeoutPromise = new Promise(resolve => {
        setTimeout(() => {
          resolve({ timeout: true });
        }, TIMEOUTDURATION);
      });

      const apiRequestPromise = await this.$http.$get(
        `${apiUrl}posts?_fields=title,link,categories,_links.wp:featuredmedia&orderby=date&order=desc&per_page=4`
      );

      const rawPosts = await Promise.race([apiRequestPromise, timeoutPromise]);

      if (rawPosts.timeout) {
        throw new Error('Timeout');
      }

      // Get categories
      for (let i = 0; i < rawPosts.length; i++) {
        if (i === 4) break;
        categories[i] = rawPosts[i]?.categories[0];
        media[i] = parseInt(
          rawPosts[i]?._links['wp:featuredmedia'][0]?.href?.replace(
            `${apiUrl}media/`,
            ''
          )
        );
      }
      const categoriesQuery = [...new Set(categories)].join(',');
      const mediaQuery = [...new Set(media)].join(',');
      const rawCategories = await this.$http.$get(
        `${apiUrl}categories?_fields=id,name,link&include=${categoriesQuery}`
      );
      const rawMedia = await this.$http.$get(
        `${apiUrl}media?_fields=media_details,id&include=${mediaQuery}`
      );
      // Format posts
      for (let i = 0; i < rawPosts.length; i++) {
        const image = rawMedia?.find(({ id }) => id === media[i]);
        const category = rawCategories?.find(({ id }) => id === categories[i]);
        posts[i] = {
          link: rawPosts[i]?.link?.toLowerCase(),
          title: rawPosts[i]?.title?.rendered,
          image: image?.media_details?.sizes.medium?.source_url,
          categoryTitle: category?.name,
          categoryLink: category?.link
        };
      }
    } catch (err) {
      // Do nothing wpengine might be down
      this.$bugsnag.notify(err);
    }

    // Set the data
    this.posts = posts;
  },
  props: {
    title: {
      type: String
    }
  }
};
</script>

<template>
  <div class="um-post">
    <a class="um-post__image-link" :href="link">
      <img class="um-post__image" :src="image" :alt="title" loading="lazy" />
    </a>
    <div class="um-post__copy">
      <a class="um-post__category" :href="categoryLink">{{ categoryTitle }}</a>
      <h3 class="um-post__title">
        <a class="um-post__link" :href="link">
          <tz-mark-down :content="title" />
        </a>
      </h3>
    </div>
  </div>
</template>

<script>
import TzMarkDown from '@trazi/tz-markdown/src/tz-markdown.vue';

export default {
  name: 'um-post',
  components: { TzMarkDown },
  props: {
    image: {
      type: String
    },
    title: {
      type: String
    },
    link: {
      type: String
    },
    categoryTitle: {
      type: String
    },
    categoryLink: {
      type: String
    }
  }
};
</script>
